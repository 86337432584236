import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Menu from "../../components/menu/menu"
import Index from "../../components/project/index"
import Intro from "../../components/project/intro"
import IntroMobile from "../../components/project/introMobile"
import Description from "../../components/project/description"
import Show from "../../components/project/show"
import ShowMobile from "../../components/project/showMobile"
import Voltar from "../../components/project/voltar.js"
import Footer from "../../components/footer/footer"

import Form from "../../components/contatos/form"
import Home from "../../components/contatos/video"
import Table from "../../components/contatos/table"
import Secondmapa from "../../components/contatos/secondmap"


const EdificioElsa = ({data}) => {
  console.log(data)
  return(
    <Layout size={useBreakpoint().mobile ? null : ["clamp(100% - 144px, 100% - 4vw, 100% - 72px)", "clamp(72px, 4vw, 144px)"]} inv={data.globalJson.invisual}>
      <SEO title="Edifício Elsa"/>
      {useBreakpoint().mobile ? (
        <>
          <Menu data={data.globalJson.menu}/>
          <IntroMobile data={data.edificioElsaJson.intro}/>

          <Description data={data.edificioElsaJson.description}/>
          <Home/>
          <div style={{height: "6vw"}}/>
          <ShowMobile data={data.edificioElsaJson.show}/>


          <Table/>
          <Description data={data.edificioElsaJson.descriptionConstruction}/>
          <ShowMobile data={data.edificioElsaJson.showConstruction}/>

          <div style={{height: "6vw"}}/>
          <Secondmapa/>


          <Form data={data.contatosJson.contactInfo.form}
          />


          <div style={{height: "6vw"}}/>
          <Voltar />
          <Footer data={data.globalJson.footer} small/>
        </>
      ) : (
        <>
          <Menu data={data.globalJson.menu}/>
          <Index data={data.edificioElsaJson.sections} filter={3}/>
          <Intro data={data.edificioElsaJson.intro}/>
          

          <Description data={data.edificioElsaJson.description}/>
          <Home/>
          <div style={{height: "6vw"}}/>
          <Show data={data.edificioElsaJson.show}/>
        

          <Table/>
          <Description data={data.edificioElsaJson.descriptionConstruction}/>
          <Show data={data.edificioElsaJson.showConstruction}/>
          <div style={{height: "6vw"}}/>

          <Secondmapa/>


          <Form data={data.contatosJson.contactInfo.form}
          />
 
          <Footer data={data.globalJson.footer}/>
        </>
      )}
      
    </Layout>
  )
}
export default EdificioElsa

export const Json = graphql`
  query edificioElsa {
    edificioElsaJson{
      sections{
        section
        subsection
      }
      intro{
        title
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      description{
        name
        title
        text
      }
      show{
        childImageSharp{
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 3840
          )
        }
        
      }
      descriptionConstruction{
        name
        title
        text
      }
      showConstruction{
        childImageSharp{
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 3840
          )
        }
      }
    }

    contatosJson{
      contactInfo{
        form{
          title
          type{
            title
            option
          }
          name
          email
          phone
          message
          termos {
            linkTxt
            link
          }
          send
        }
      }
    }
    
    globalJson{
      invisual{
        link
        linkTxt
      }
      menu{
        leftPage{
          background{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          content{
            subtitle
            title
            text
            link
            linkTxt
          }
          info{
            address
            contact{
              newsletter
              email
              phone
            }
          }
          livro{
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                width: 3840
              )
            }
          }
        }
        rightPage{
          bg{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          links{
            link
            linkTxt
          }
          highlights{
            subtitle
            title
            list{
              img{
                childImageSharp{
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    width: 3840
                  )
                }
              }
              title
              subtitle
              link
            }
          }
        }
      }
      footer{
        livro{
          childImageSharp{
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              width: 3840
            )
          }
        }
        bg{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contacts{
          email
          phone1
          txt
        }
        address
        privacyInfo{
          linkTxt
          link
        }
      }
    }
  }
`