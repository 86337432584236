import React from "react"
import DogVideo from "../../images/video/ELSA.mp4";
import styled from "styled-components"
import Poster from "../../images/video/capa3.png"


export default function Home() {
  return (
    <StyledVideo>
    <video controls poster={Poster} style={{display:"block", marginLeft: "auto", marginRight: "auto"}}>
      <source src={DogVideo} type="video/mp4" />
    </video>
    </StyledVideo>
  );
}

const StyledVideo = styled.div`
    video{
        @media only screen and (max-width:700px){
            width:100%;
            height:50%;
        }

        @media only screen and (min-width:1200px){
            width:50%;
            height:50%;
        }
    }
`
