import React from "react"
import styled from "styled-components"
import img from "../../images/projetos/aprt/elsa/Plantas/planta icon-05.png";
import {Link} from "gatsby"




export default function Table() {
    return (
        <StyledTable>
         <div class="wrapper">
        <div class="table" >
            <div class="wrap-content">
                <div class="wrap-title">
                    <div class="id">
                        <h4>PISO</h4>
                    </div>
                    <div class="country">
                        <h4>TIPOLOGIA</h4>
                    </div>
                    <div class="area">
                        <h4>FRAÇÃO</h4>
                    </div>
                    <div class="Total">
                        <h4>ÁREA M²</h4>
                    </div>
                    <div class="Population">
                        <h4>VALOR</h4>
                    </div>
                    <div class="Planta">
                        <h4>PLANTA</h4>
                    </div>
                </div>
                <div class="wrap-item">
                    <div class="id">
                        <h4>PISO 1</h4>
                    </div>
                    <div class="country">
                        <h4>T3</h4>
                    </div>
                    <div class="area">
                        <h4>G</h4>
                    </div>
                    <div class="Total">
                        <h4>138.5</h4>
                    </div>
                    <div class="Population">
                        <h4>600.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto3" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item" style={{borderBottom: "1px solid #b4b4b4"}}>
                    <div class="id">
                        <h4></h4>
                    </div>
                    <div class="country">
                        <h4>T2</h4>
                    </div>
                    <div class="area">
                        <h4>I</h4>
                    </div>
                    <div class="Total">
                        <h4>117.7</h4>
                    </div>
                    <div class="Population">
                        <h4>425.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto2" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item">
                    <div class="id">
                        <h4>PISO 2</h4>
                    </div>
                    <div class="country">
                        <h4>T3</h4>
                    </div>
                    <div class="area">
                        <h4>K</h4>
                    </div>
                    <div class="Total">
                        <h4>138.5</h4>
                    </div>
                    <div class="Population">
                        <h4>650.000,00€</h4>
                    </div>
                    <div class="Planta">
                    <a href="images/foto3" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item">
                    <div class="id">
                        <h4></h4>
                    </div>
                    <div class="country">
                        <h4>T2</h4>
                    </div>
                    <div class="area">
                        <h4>L</h4>
                    </div>
                    <div class="Total">
                        <h4>120</h4>
                    </div>
                    <div class="Population">
                        <h4>425.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto2" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item" style={{borderBottom: "1px solid #b4b4b4"}}>
                    <div class="id">
                        <h4></h4>
                    </div>
                    <div class="country">
                        <h4>T2</h4>
                    </div>
                    <div class="area">
                        <h4>M</h4>
                    </div>
                    <div class="Total">
                        <h4>119.5</h4>
                    </div>
                    <div class="Population">
                        <h4>430.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto2" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item">
                    <div class="id">
                        <h4>PISO 3</h4>
                    </div>
                    <div class="country">
                        <h4>T4</h4>
                    </div>
                    <div class="area">
                        <h4>N</h4>
                    </div>
                    <div class="Total">
                        <h4>208.7</h4>
                    </div>
                    <div class="Population">
                        <h4>950.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto4" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item">
                    <div class="id">
                        <h4></h4>
                    </div>
                    <div class="country">
                        <h4>T2</h4>
                    </div>
                    <div class="area">
                        <h4>O</h4>
                    </div>
                    <div class="Total">
                        <h4>117.7</h4>
                    </div>
                    <div class="Population">
                        <h4>480.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto2" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item" style={{borderBottom: "1px solid #b4b4b4"}}>
                    <div class="id">
                        <h4></h4>
                    </div>
                    <div class="country">
                        <h4>T2</h4>
                    </div>
                    <div class="area">
                        <h4>Q</h4>
                    </div>
                    <div class="Total">
                        <h4>117.7</h4>
                    </div>
                    <div class="Population">
                        <h4>445.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto2" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item">
                    <div class="id">
                        <h4>PISO 4</h4>
                    </div>
                    <div class="country">
                        <h4>T3</h4>
                    </div>
                    <div class="area">
                        <h4>S</h4>
                    </div>
                    <div class="Total">
                        <h4>138.5</h4>
                    </div>
                    <div class="Population">
                        <h4>690.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto3" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item" style={{borderBottom: "1px solid #b4b4b4"}}>
                    <div class="id">
                        <h4></h4>
                    </div>
                    <div class="country">
                        <h4>T2</h4>
                    </div>
                    <div class="area">
                        <h4>U</h4>
                    </div>
                    <div class="Total">
                        <h4>119.5</h4>
                    </div>
                    <div class="Population">
                        <h4>450.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto2" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>
                <div class="wrap-item">
                    <div class="id">
                        <h4>PISO 5</h4>
                    </div>
                    <div class="country">
                        
                        <h4>T3</h4>
                    </div>
                    <div class="area">
                        <h4>W</h4>
                    </div>
                    <div class="Total">
                        <h4>134.8</h4>
                    </div>
                    <div class="Population">
                        <h4>690.000,00€</h4>
                    </div>
                    <div class="Planta">
                        <a href="images/foto3" target="_blank"><img src={img} /></a>
                        <h4></h4>
                    </div>
                </div>              
            </div>
        </div>
    </div>
    </StyledTable>
    
    )
}

const StyledTable = styled.div`
.table {
    max-width: 1200px;
    margin: 50px auto 0;
    box-shadow: 0 0.15rem 1.75rem rgba(33, 40, 50, 0.15);
    font-family: AktivGrotesk-Regular;

}

.table .top-title {
    background-color: rgba(33, 40, 50, 0.03);
    border-bottom: 1px solid rgba(33, 40, 50, 0.03);
    padding: 1rem 1.35rem;
}

.top-title h2 {
    font-size: 16px;
    color: #626364;
    letter-spacing: 2px;
    font-weight: 500;
}

.wrap-content {
    padding: 10.2rem;
    padding-top: 0px;
    padding-bottom:0px;
    margin:6vw 0;
    margin-top:0px;
    margin-bottom:0px;
    background-color: #f7f7f7;
    text-align:center;

    @media only screen and (max-width:1440px){
        display: block;
    margin-left: auto;
    margin-right: auto

    }
    @media only screen and (max-width:768px){
        
        padding:0;

    }
}

.wrap-title {
    padding: 30px 0;
}

.wrap-content h4 {
    @media only screen and (max-width:768px){
        font-size:10px;
    }
}


.wrap-title .id,
.wrap-title .country,
.wrap-title .Planta,
.wrap-title .Population,
.wrap-title .Total,
.wrap-title .area {
    font-size: 16px;
    font-weight: bold;
    color: #626364;
  }

.wrap-title,
.wrap-item {
    display: grid;
    align-items: center;
    grid-template-columns: 15fr 15fr 15fr 15fr 15fr 15fr;
}

.wrap-title {
    border-bottom: 1px solid #b4b4b4;
}

.wrap-item {
    padding: 30px 0;
    
}

.wrap-item:nth-child(odd) {
    
}

.wrap-item .id {
    font-size: 16px;
    color: #626364;
    letter-spacing: 2px;    
    font-weight: bold;
}

.id {
    padding: 0 10px;
}

.wrap-item .country,
.wrap-item .Population,
.wrap-item .Planta,
.wrap-item .Total,
.wrap-item .area {
    font-size: 16px;
    font-weight: 400;
    color: #626364;
    letter-spacing: 2px;
}

.Planta img {
    width: 24px;
    margin-right: 8px;
    margin-left: 60px;

    @media only screen and (max-width:1200px){
        margin-left: 64px;
    }
    @media only screen and (max-width:768px){
        margin-left: 20px;
    }
}

.wrap-item .Planta {
    display: flex;
    align-items: center;
}



@media (max-width:767px) {
    .top-title h2 {
        font-size: 14px;
    }
    .wrap-title .id,
    .wrap-title .country,
    .wrap-title .Population,
    .wrap-title .Planta,

    .wrap-title .area,
    .wrap-item .country,
    .wrap-item .Population,
    .wrap-item .Planta,

    .wrap-item .area,
    .wrap-item .id {
        font-size: 12px;
    }
    .wrap-title,
    .wrap-item {
        grid-template-columns: 15fr 15fr 15fr 15fr 15fr 15fr;
    }
}


`