import React from "react"
import styled from "styled-components"

const Secondmapa = () => {

  return (
    <StyledSecondSMapa>
        <iframe src="https://snazzymaps.com/embed/427396" className="maps"></iframe>
    </StyledSecondSMapa>
  )
}

export default Secondmapa

const StyledSecondSMapa = styled.div`
position: relative;
height: 33vw;
width: 100%;
.maps{
  width: 100%;
  height: 100%;
  border: 0;
}
@media only screen and (max-width: 768px){
  height: 100vw;
}
`